.home-popup__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .home-popup__content {
    background-color: #f8f8f8;
    border-radius: 8px;
    width: 90%;
    max-width: 1300px;
    height: 700px;
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: popup-fade-in 0.3s ease;
  
    .home-popup__close {
      position: absolute;
      top: -40px;
      right: -90px;
      background: none;
      border: none;
      font-size: 22px;
      cursor: pointer;
    }
  }
  
  @keyframes popup-fade-in {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  